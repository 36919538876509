<template>
  <SortingList />
</template>

<script>
import SortingList from "@/components/SortingList"

export default {
  name: 'Sorting',
  components:{
      SortingList,
  },
  mounted(){

  }
}
</script>
